import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Single Arm OHS’s 4×6 per arm`}</p>
    <p>{`DB Death March 4×6 steps per leg`}</p>
    <p>{`then,`}</p>
    <p>{`3 rounds for time:`}</p>
    <p>{`10-Right Arm DB OH Walking Lunge Steps`}</p>
    <p>{`20-Alternating Hang Power Snatch (10/arm)`}</p>
    <p>{`10-Left Arm DB OH Walking Lunge Steps`}</p>
    <p>{`20-T2B`}</p>
    <p>{`RX=35/25`}{`#`}{`, RX+=50/35`}{`#`}</p>
    <p>{`*`}{`Walking Lunge in your lane`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      